import { render, staticRenderFns } from "./ClientPayment.vue?vue&type=template&id=572e6f39&"
import script from "./ClientPayment.vue?vue&type=script&lang=js&"
export * from "./ClientPayment.vue?vue&type=script&lang=js&"
import style0 from "./ClientPayment.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VBtn,VCard,VDialog,VFileInput,VSelect,VSwitch,VTextField,VTextarea})
